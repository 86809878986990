
























































import { Component, Prop, Vue } from "vue-property-decorator";
import AbortButton from "@/components/Button/AbortButton.vue";
import SaveButton from "@/components/Button/SaveButton.vue";
import i18n from "@/i18n";
import { BvModalEvent } from "bootstrap-vue";

@Component({
  components: { SaveButton, AbortButton },
})
export default class EditSubcategoryDetailsModal extends Vue {
  public name = "EditSubcategoryDetailsModal";

  @Prop({ type: String, required: true })
  protected modalId!: string;

  @Prop({ type: String, default: () => i18n.t("general.yes") })
  public saveBtnLabel!: string;

  @Prop({ type: String, default: () => i18n.t("general.no") })
  public cancelBtnLabel!: string;

  @Prop({ type: Boolean, default: () => true })
  public hideHeader!: boolean;

  @Prop({ type: String, default: () => "m-4" })
  public bodyClass!: string;

  @Prop({ type: String, default: () => "border-0" })
  public headerClass!: string;

  @Prop({ type: Boolean, default: () => false, required: false })
  public noCloseOnBackdrop?: boolean;

  public onHide(event: BvModalEvent) {
    if (event.trigger === "backdrop") this.$emit("backdrop");
    if (event.trigger === "headerclose") this.$emit("headerclose");
    this.$emit("hide-modal");
  }

  cancel() {
    this.$emit("cancel");
  }

  ok() {
    this.$emit("ok");
  }
}
































































import { mixins } from "vue-class-component";
import { Component } from "vue-property-decorator";
import { namespace } from "vuex-class";
import FscModal from "@/components/Modal/FscModal.vue";
import FscCard from "@/components/Card/FscCard.vue";
import EditSubcategoryDetailsModal from "@/views/LMSExamQuestions/ThemeLoop/EditSubcategoryDetailsModal.vue";
import SaveButton from "@/components/Button/SaveButton.vue";
import AbortButton from "@/components/Button/AbortButton.vue";

const LMSThemeLoopModule = namespace("lms-theme-loop");
@Component({
  components: {
    AbortButton,
    SaveButton,
    FscCard,
    FscModal,
    EditSubcategoryDetailsModal,
  },
})
export default class QuestionCategories extends mixins() {
  public name = "QuestionCategories";

  @LMSThemeLoopModule.Action("findCategoriesSubcategories")
  public findCategoriesSubcategories!: any;

  @LMSThemeLoopModule.Getter("getCategoryList")
  public categorySubcategoryData!: any;

  @LMSThemeLoopModule.Getter("getCategoryListIsLoading")
  public categoryListIsLoading: any;

  @LMSThemeLoopModule.Action("subcategoryDetailsUpsert")
  public subcategoryDetailsUpsert!: any;

  public categorySubcategoryDataToPrint!: Array<any>;
  private openedIndex!: any;

  private currentSubcategoryUrlValue = "";
  private currentSubcategoryNumber!: any;
  private currentSubcategoryUrlValueInputKey = 0; // force re-render
  data() {
    return {
      categorySubcategoryDataToPrint: [] as Array<any>,
      openedIndex: null as any,
    };
  }

  async mounted() {
    await this.findCategoriesSubcategories();

    this.categorySubcategoryDataToPrint = [
      {
        name: "Grundstoff",
        id: "basic",
        categories: this.categorySubcategoryData["basic"],
      },
      {
        name: "Zusatzstoff",
        id: "classSpecific",
        categories: this.categorySubcategoryData["classSpecific"],
      },
    ];
  }

  public toggleAccordion(categoryNumber: string) {
    if (this.openedIndex === categoryNumber) {
      this.openedIndex = null; // Close the accordion
    } else {
      this.openedIndex = categoryNumber;
    }

    this.$emit("on-any-category-click", this.openedIndex != null, categoryNumber);
  }

  public onSubcategoryClick(name: string): void {
    this.$emit("on-sub-category-click", name);
  }

  public showSubcategoryDetailsModal(url: string, subCategoryNumber: string): void {
    this.currentSubcategoryUrlValue = "";
    this.currentSubcategoryNumber = subCategoryNumber;

    this.$nextTick(() => {
      this.currentSubcategoryUrlValue = url;
      this.currentSubcategoryUrlValueInputKey++; // Force b-input to re-render
      this.$bvModal.show("edit-subcategory-details-modal");
    });
  }

  public cancelSubcategoryDetails() {
    this.$bvModal.hide("edit-subcategory-details-modal");
  }

  public async saveSubcategoryDetails() {
    await this.subcategoryDetailsUpsert({
      subCategoryNumber: this.currentSubcategoryNumber,
      subCategoryUrl: this.currentSubcategoryUrlValue,
    });
    this.$bvModal.hide("edit-subcategory-details-modal");
    // Find and update only the modified subcategory
    // Must be triggered on successful save // TODO
    this.updateSubcategoryUrlInCurrentlyPrintedList(this.currentSubcategoryNumber, this.currentSubcategoryUrlValue);
  }

  private updateSubcategoryUrlInCurrentlyPrintedList(subCategoryNumber: string, newUrl: string) {
    for (const section of this.categorySubcategoryDataToPrint) {
      for (const category of section.categories) {
        const subcategory = category.subcategories.find((sub: { number: string }) => sub.number === subCategoryNumber);
        if (subcategory) {
          subcategory.url = newUrl; // Update only the modified subcategory
          return; // Exit loop early after updating
        }
      }
    }
  }

  async pasteSubcategoryUrlValueFromClipboard() {
    try {
      const text = await navigator.clipboard.readText();
      if (text) {
        this.currentSubcategoryUrlValue = ""; // Clear it first
        this.$nextTick(() => {
          this.currentSubcategoryUrlValue = text;
          this.currentSubcategoryUrlValueInputKey++; // Change key to force re-render
        });
      }
    } catch (err) {
      console.error("Failed to read clipboard: ", err);
    }
  }
}
